<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button type="button" @click="goBack()" class="btn btn-inverse" title="Kembali">Kembali</button>
        </span>
      </h3>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <center class="m-t-30">
            <img :src="state.detail.avatar" style="width:150px; height:150px" class="img-circle">
            <h4 class="card-title m-t-10">{{state.detail.name}}</h4>
            <h6 class="card-subtitle">{{state.detail.email}}</h6>
          </center>
        </div>
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-6 pull-left">
            <small class="text-muted">Email address</small>
            <h6>{{state.detail.name}}</h6>
            <small class="text-muted">Email address</small>
            <h6>{{state.detail.email}}</h6>
            <small class="text-muted p-t-30 db">Phone</small>
            <h6>{{state.detail.phone}}</h6>
            <small class="text-muted p-t-30 db">Address</small>
            <h6>{{state.detail.address}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import ImageItem from "@/components/img/imgItem";
export default {
  components: {
    RotateSquare5,
    ImageItem
  },
  computed: {
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.user;
    }
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false
      };
      this.$store.commit("user/STATE", state);
      this.$store.dispatch("user/getUserById", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("user/onCancel");
    }
  }
};
</script>
